<template>
    <div class="main">
      <div class="main-item header p-5 bg-white">
        <div class="header-1 flex justify-between">
          <div class="header-1-1">我的应用 / 修改应用</div>
          <div class="header-1-2 flex">

          </div>
        </div>

      </div>
      <div class="main-body p-5 pr-24 pb-8 bg-white">
          <el-form :model="form" ref="form" :rules="rules" label-width="100px" :inline="false" >
              <el-form-item label="应用名称" prop='name' >
                  <el-input v-model="form.name" type="text" maxlength="20" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="应用描述" prop='remark'>
                  <el-input type="textarea" v-model="form.remark" :show-word-limit='true'  :maxlength="200" :autosize="{ minRows: 5, maxRows: 5}"></el-input>
              </el-form-item>
              <el-form-item label="应用ID" >
                <span>{{resData.appKey}}</span>
              </el-form-item>
              <el-form-item label="应用秘钥">
                <span>{{resData.appSecret}}</span>
              </el-form-item>
          </el-form>
          <div class="mt-16 flex justify-center">
              <el-button class="l-btn-primary"  @click="clickConfirm">确定</el-button>
              <span class="ml-10">
              <el-button class="l-btn-cancel"  @click="clickCancle">返回</el-button>
              </span>
          </div>
      </div>
    </div>
</template>
  <script>
  export default {
    // el: '', // string | Element
    name: '', // string
    // parent: '', // Vue instance
    // functional: false, // boolean
    // delimiters: ['{{', '}}'], // Array<string>
    // comments: false,
    components: {},// Object
    // directives: {}, // Object
    // filters: {}, // Object
    // extends: '', // Object | Function
    // mixins: [], // Array<Object>
    // inheritAttrs: true, // boolean
    // model: '', // { prop?: string, event?: string }
    props: {}, // Array<string> | Object
    data () {
      return {
        resData:{},
        isvId:'',
          form:{
              name:'',
              remark:''
          },
          rules:{
              name:[
  { required: true, message: '请输入应用名称', trigger: 'blur' },

              ]
          }
      }
    },
    computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
    // watch: {}, // { [key: string]: string | Function | Object | Array }
    beforeCreate () {},
    created () {
      this.isvId = this.$route.query.isvId
this.getIsvInfoGet(this.isvId)
      
    },
    beforeMount () {},
    mounted () {},
    beforeUpdate () {},
    updated () {},
    activated () {},
    deactivated () {},
    beforeDestroy () {},
    destroyed () {},
    methods: {
      getIsvInfoGet(isvId){
return this.$http('get','/op/website/isv/getIsvInfo',
{
  isvId
},{
  /* mock: 22 */
}).then(
  res=>{
    this.resData = res
    this.form.name = res.isvName
    this.form.remark = res.remark
  }
)
},
clickConfirm(){

 this.$refs.form.validate((valid) => {
          if (valid) {
            this.updateIsvInfoPost(this.form.name,this.form.remark,this.isvId).then(()=>{
this.$router.push({name:'MyApplicationDetail',query:{isvId:this.isvId}})
})
          } else {
            return;
          }
        })


},
clickCancle(){
this.$router.push({name:'MyApplicationDetail',query:{isvId:this.isvId}})
},
      updateIsvInfoPost(name,remark,isvId){
       return this.$http('post','/op/website/isv/updateIsvInfo',{
         isvId,
name,
remark
       },{
          /* mock: 22 */
        }).then(
//           res=>{
// this.tableData =res
//           }
        )
      },
    } // { [key: string]: Function }
    // template: '', // render // string
    // renderError: '' // (createElement: () => VNode, error: Error) => VNode
  }
  </script>
  <style lang="scss" scoped>
    .main-item{
      background: ffffff;
    }
  .header-1-1{
    border-left-width: 2px;
    padding-left: 10px;
    border-color: #333333;
  }
  .header-1-2{
    font-size: 14px;
  }
.main-body{
    margin-top: 22px;
}
.main ::v-deep .el-form-item__label{
    padding-right: 20px;
}

.el-form-item ::v-deep .el-textarea__inner{
height: 134px;
}
  </style>
  